<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/internal-announcements") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 公告設定

        b-card-body(v-if="!loading")
          form.form-horizontal(role="form")
            .row
              .col-lg-8
                .form-group.mb-3
                  label 公告標題
                  br
                  input.form-control(
                    v-model="announceDetail.title",
                    type="text",
                    placeholder="請輸入公告標題",
                    :disabled="announceId !== 'create' && isLock"
                  )
            .row
              .col-lg-5
                .form-group.mb-3
                  label 上架日期
                  br
                  .row
                    .col-6
                      date-picker(
                        v-model="announceDetail.start_date",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇公告起始日期"
                        :disabled="announceId !== 'create' && isLock"
                      )
                    .col-6
                      date-picker(
                        v-model="announceDetail.start_time",
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                        :disabled="announceId !== 'create' && isLock"
                      )
            .row
              .col-lg-5
                .form-group.mb-3
                  label 下架日期
                  br
                  .row
                    .col-6
                      date-picker(
                        v-model="announceDetail.end_date",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇公告下架日期"
                        :disabled="announceId !== 'create' && isLock"
                      )
                    .col-6
                      date-picker(
                        v-model="announceDetail.end_time",
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                        :disabled="announceId !== 'create' && isLock"
                      )
                    .col-auto (不填寫結束日期與時間，即為永久顯示)

            .row
              .col-lg-10
                #ckeditor-editor.form-group.mb-3
                  label.form-label-row.justify-content-between
                    span 正文編輯
                  //- 稿件本文編輯區域
                  ckeditor(:editor="editor",v-model="announceDetail.content")
            .row 
              .col(v-if="isLock")
                b-button.width-lg.mr-1(variant="primary" @click="isLock = false") 我要編輯
              .col(v-else)
                b-button.width-lg.mr-1(variant="primary" @click="onAnnounceSave") 儲存
                b-button.width-lg(variant="primary" @click="releaseAnnounce" :disabled="!announceDetail.id") 發佈


</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import { Editor as ClassicEditor } from 'ctinews-editor'

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      // 處理編輯器元件
      editor: ClassicEditor,
      // 文章內容
      articleContent: "",
      title: "編輯內部公告",
      items: [
        {
          text: "公告",
          href: "/announcement",
        },
        {
          text: "公告列表",
          href: '/announcement',
        },
        {
          text: "編輯內部公告",
          active: true,
        }
      ],
      editorOption: {
        // Some Quill options...
        theme: "snow",
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
              {
                size: [],
              },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ],
            [
              {
                script: "super",
              },
              {
                script: "sub",
              },
            ],
            [
              {
                header: [false, 1, 2, 3, 4, 5, 6],
              },
              "blockquote",
              "code-block",
            ],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ],
            [
              "direction",
              {
                align: [],
              },
            ],
            ["link", "image", "video"],
            ["clean"],
          ],
        },
      },

      loading: true,
      isLock: false,

      announceId: '',
      announceDetail: {
        // id: "",
        // type: {},
        // content_type: {},
        title: "",
        content: "",
        start_date: null,
        start_time: "",
        end_date: null,
        end_time: "",
        // status: 1,
        // ann_status: 1
      },


      news_type: 0,
      news_platform: 0,
      send_broadcast: 0,

      value: '',
      date: '',
      time: '',
      visible: true,
      option: '',
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa",
      ],

      relevant_option: ['tag', '稿號'],
      relevant_value: '',
      extension_value: '',

      guide_data: [
        {
          text: '',
          link: ''
        }
      ],

      second_data: [
        {
          main: '',
          sub: ''
        }
      ],
      tag_result: [],
      tag_options: [
        { id: 1, text: 'text' },
        { id: 2, text: 'text1' },
        { id: 3, text: 'text2' },
        { id: 4, text: 'text3' }
      ]

    };
  },
  computed: {

  },
  created () {
    // 獲得操作公告ID
    this.announceId = this.$route.params.NewAnnounceId
    // 獲得單一公告文章
    this.getInternalAnnounceDetail()
  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2
  },
  watch: {

  },
  methods: {
    // 獲得單一公告
    getInternalAnnounceDetail () {
      if (this.announceId == 'create') {
        this.loading = false
        this.isLock = false
        return
      }
      this.isLock = true
      this.$root.apis.getInternalAnnounceDetail(this.announceId, this.getNewsDetailSuccess)
    },
    getNewsDetailSuccess (_response) {
      this.announceDetail = {
        ..._response.body.data,
      }
      this.loading = false
    },
    onAnnounceSave () {
      if (!this.checkAnnounceData()) {
        this.$root.common.showErrorDialog("請確定必填欄位都有填寫完成哦！")
        return
      }
      const post_data = {
        title: this.announceDetail.title,
        content: this.announceDetail.content,
        start_date: this.announceDetail.start_date,
        start_time: this.announceDetail.start_time,
        end_date: this.announceDetail.end_date,
        end_time: this.announceDetail.end_time,
      }
      if (this.announceId === 'create') {
        this.createAnnounce(post_data)
      } else {
        this.updateAnnounce(post_data)
      }
    },
    // 建立公告
    createAnnounce (_data) {
      this.$root.apis.createInternalAnnounceDetail(_data, this.createAnnounceSuccess, this.announceError)
    },
    createAnnounceSuccess (_response) {
      this.$set(this.announceDetail, 'id', _response.body.data)
      this.$root.common.showSingleNotify("儲存成功！")
    },
    announceError (_error) {
      const errorMsg = typeof _error.body.data === 'object' ? '請確定必填欄位都有填寫完成哦！' : _error.body.data
      this.$root.common.showErrorNotify(errorMsg)
    },
    // 更新公告
    updateAnnounce (_data) {
      this.$root.apis.updateInternalAnnounceDetail(this.announceId, _data, this.updateAnnounceSuccess, this.announceError)
    },
    updateAnnounceSuccess (_response) {
      this.$root.common.showSingleNotify("更新成功！")
    },
    // 檢查必填
    checkAnnounceData () {
      const requiredData = ['title', 'content', 'start_date', 'start_time']
      const invalidFieldExist = requiredData.some(_field => {
        return typeof this.announceDetail[_field] === 'object'
          ? !this.announceDetail[_field]?.id
          : !this.announceDetail[_field]
      })
      if (invalidFieldExist) return false
      return true
    },
    // 發佈公告
    releaseAnnounce () {
      if (this.announceDetail.id) {
        this.$root.apis.releaseInternalAnnounceDetail(this.announceDetail.id, this.releaseAnnounceSuccess, this.releaseannounceError)
      }
    },
    releaseAnnounceSuccess (_response) {
      this.$root.common.showSingleNotify('公告發佈完成！')
      this.$router.push({ path: '/internal-announcements' })
    }



  }
};
</script>


<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
</style>
