import { render, staticRenderFns } from "./internal-announcements-editor.vue?vue&type=template&id=577e1e40&scoped=true&lang=pug"
import script from "./internal-announcements-editor.vue?vue&type=script&lang=js"
export * from "./internal-announcements-editor.vue?vue&type=script&lang=js"
import style0 from "./internal-announcements-editor.vue?vue&type=style&index=0&id=577e1e40&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577e1e40",
  null
  
)

export default component.exports